.confidence {
  margin: 4px;
  height: 144px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #F8F8F8;
  box-shadow: 0px 3.7px 3.4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.wrapper {
  margin: 11px 21px 11px 21px;
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #20324D;
  margin-bottom: 4px;
}

.body {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 14px;
}

.bold {
  font-weight: 700;
}

.select {
  width: 100%;
}
