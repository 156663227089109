.button {
    background: #20324D;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    height: 23px;
    width: 62px;
    border-radius: 11px;
    border-width: 0px;
  }
  
  .button:disabled {
    background: #20324D80;
  }
  
  .button:hover {
    cursor: pointer;
  }