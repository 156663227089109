.navbar {
  width: 70px;
  background-color: #20324D;
  height: 100%;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 15px;
  position: relative;
}

.logo {
  margin: 18px 11px 82px 11px;
}

.nav {
  cursor: pointer;
  text-align: center;
  margin-bottom: 30px;
}

.settings {
  cursor: pointer;
  position: absolute;
  bottom: 27px;
  width: 100%;
  text-align: center;
}