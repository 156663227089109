.panel {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #20324D;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.title {      
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  position: relative;
  margin-top: 22px;
  margin-left: 26px;
}

.goal {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  background-color: #FFFEFE;
  position: relative;
  margin: 26px;
}

.header {
  background-color: #EFF2F6;
  height: 53px;
  flex-direction: row;
  display:flex;
}

.goalText {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  margin:16px;
  color: #20324D;
}

.body {
  flex-grow:1;
  display: flex;

  flex-direction: column;
  color: #20324D;
  margin: 20px 29px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
}

.bold {
  font-weight: 700;
}

.icon{
  height:21px;
  width:21px;
  margin-top:20px;
  margin-left:29px;
}

.problem {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.goalStatement {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: #2237EC;
}
