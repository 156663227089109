.blackout {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.comingSoon {
  z-index: 2000;
}

.wrapper {
  position: relative;
  width: 416px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 10px;
  z-index: 5000;
}

.title {
  position:relative;
  height: 52px;
  padding-left: 20px;
  background: #17A8D6;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 20px;
  line-height: 52px;
  color: #FFFFFF;
  background-color: #F442AD;
}

.message {
  padding: 15px 20px 20px 20px;
  background-color: #FFFFFF;
  color: #000000;
  font-weight: normal;
  font-size: 20px;
}

.arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  overflow: hidden;
  z-index: 10000;
}

.arrow::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 22px 10px 0;
  border-color: transparent #ffffff transparent transparent;
  -webkit-filter: drop-shadow(0px 4px 1px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px 4px 1px rgba(0, 0, 0, 0.1));
}

[x-placement*="right"] > .wrapper {
  margin-left: 20px;
}

[x-placement*="right"] > .arrow {
  left: 1px;
}

[x-placement*="left"] > .wrapper {
  margin-right: 20px;
}

[x-placement*="left"] > .arrow {
  right: 1px;
  transform: rotate(180deg);
}

[x-placement*="left"] > .arrow::after {
  -webkit-filter: drop-shadow(0px -4px 1px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px -4px 1px rgba(0, 0, 0, 0.1));
}
