.container {
  width: 100%;
  min-width: 1440px;
  height: 100vh;
  min-height: 900px;
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main {
  display: flex;
  background-color: #E4EAEC;
  margin: 15px;
  margin-bottom: 5px;
  border-radius: 25px;
}

.goals {
  margin: 0 13px 0 13px;
  position: relative;
}

.seperator {
  color: #FFFFFF;
  background-color: #FFFFFF;
  border: 0 none;
  height: 1px;
  margin: 14px 10px 7px 10px;
}

.selectGoals {
  margin: 0 10px 0 10px;
}

.selectGoals > p {
  color: #000000;
  font-weight: 300;
  font-size: 19px;
  line-height: 24px;
}

.link {
  cursor: pointer;
  font-size: 19px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #2E99B4;
}

.bold {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.goalCards {
  position: relative;
  z-index: 600;
  background-color: #E4EAEC;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

.mainGoals > span {
  display: inline-block;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #20324D;
  margin-bottom: 13px;
}

.subGoals > p {
  font-size: 18px;
  line-height: 23px;
  color: #20324D;
  margin-bottom: 10px;
}

.footerSpacer {
  margin-bottom: 55px;
}

.footer {
  position: absolute;
  margin: 10px;
  bottom: 0;
  right: 0;
}

.nextText {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #20324D;
}

.summary {
  display: flex;
  flex-grow: 1;
}