.goal {
  margin: 4px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #FFFFFF;
  box-shadow: 0px 3.7px 3.4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.goal:hover {
  box-shadow: 0px 0px 0px 4px #27AE60;
}

.selected {
  box-shadow: 0px 0px 0px 4px #27AE60;
}

.level {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  padding: 5px 9px 4px 9px;
}

.level.green {
  background-color: #10AA51;
}

.level.blue {
  background-color: #2237EC;
}

.level.yellow {
  background-color: #F3B404;
}

.level.orange {
  background-color: #FD6C09;
}

.level.red {
  background-color: #EB5757;
}

.level.black {
  background-color: #474747;
}

.title {
  color: #FFFFFF;
  background-color: #20324D;
  font-weight: bold;
  font-size: 19px;
  line-height: 25px;
  padding: 11px 23px 10px 23px;
  border-bottom: 1px solid #373737;
  position: relative;
}

.title > img {
  position: relative;
  bottom: -2px;
  width: 21px;
  height: 21px;
}

.title > span {
  margin-left: 11px;
}

.content {
  margin: 18px 13px 14px 23px;
  height: 362px;
  padding-right: 10px;
  overflow-y: auto
}

.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-track {
  background-image: url("../icons/scroll_track.png");
  background-repeat: repeat-y;
  background-size: contain;
}

.content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #20324D;
}

.result {
  color: #000000;
  font-size: 17px;
  line-height: 22px;
  height: 100px;
  margin-bottom: 28px;
}

.resultList {
  /* height: 115px; */
}

.resultList > div {
  margin-top: 3px;
}

.bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #20324D;
}

.boldFont {
  font-weight: 700;
}

.bulletGreen {
  background-color: #27AE60;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  display: inline-block;
  margin: 2px 10px 2px 10px;
}

.learnMoreItem {
  position: relative;
  margin: 2px 10px 3px 45px;
}

.bulletBlack {
  position: absolute;
  top: 8px;
  left: -12px;
  background-color: #000000;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  display: inline-block;
}

.separator {
  color: #DBDBDB;
  background-color: #DBDBDB;
  border: 0 none;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 14px;
}

.goalList {
  margin: 3px 0 0 10px;
  position: relative;
  height: 40px;

  font-size: 16px;
  line-height: 20px;
}

.goalList > img {
  position: relative;
  bottom: -5px;
  width: 21px;
  height: 21px;
  margin-right: 8px;
}

.spacer {
  margin-bottom: 6px;
}

.learnMore {
  position: absolute;
  right: 0;
  bottom: 0;
}

.learnMore > button {
  font-size: 12px;
  line-height: 14px;
  color: #17A8D6;
  border: none;
  background-color: inherit;
}

.learnMore > button:hover {
  cursor: pointer;
}

/* .ontop {
  position: absolute;
  z-index: 1000;
} */

/* Popper Arrow shenanigans */
.arrow {
  width: 30px;
  height: 60px;
  position: absolute;
  overflow: hidden;
  z-index: 10;
}

.arrow::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background: white;
  transform: translateY(50%) rotate(45deg);
  top: 0;
  left: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
}

[x-placement*="right"] > .arrow {
  left: 1px;
}

[x-placement*="left"] > .arrow {
  right: 1px;
  transform: rotate(180deg);
}

[x-placement*="left"] > .arrow::after {
  box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.25);
}
