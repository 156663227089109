.panel {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #FFFEFE;
  position: relative;
}

.content{
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  height: 100%;
}


.title{
  color: #20324D;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  margin-top: 22px;
}

.subtitle{
  color: #20324D;
  font-style: italic;
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;

  justify-content: space-between;
  margin-right: 26px;
  flex-direction:row;
  display: flex;
  padding-top:5px;
}

.textarea {
  flex-grow: 1;
  margin-top: 15px;
  margin-bottom: 15px;
}

.note {
  resize:none;
  width: 93%;
  padding: 15px;
  height: calc(100% - 30px);
  font-family: Source Sans Pro;
  font-size: 15px;
}





