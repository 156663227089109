.container {
  width: 100%;
  min-width: 1440px;
  height: 100vh;
  min-height: 900px;
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #E4EAEC;
  margin: 15px;
  margin-bottom: 5px;
  border-radius: 25px;
  padding: 20px 18px 23px 18px;
  min-height: 754px;
}

.seperator {
  color: #FFFFFF;
  background-color: #FFFFFF;
  border: 0 none;
  height: 1px;
  margin-top: 27px;
  margin-bottom: 28px;
  width: 100%;
}

.header {
  display: flex;
  align-items: baseline;
  padding-right: 10px;
}

.mainText {
  flex-grow: 1;
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 0px 3px;
}

.mainText > span {
  font-size: 19px;
  font-weight: 300;
  line-height: 24px;
}

.languageToggle {
  font-weight: 300;
  font-size: 15px;
  line-height: 30px;
  margin-right: 11px;
}

.goals {
  display: flex;
  justify-content: center;
}

.goals > div {
  width: 30%;
  margin: 0 20px;
}

.goalBigger {
  transition: transform .25s;
}

.goalSmaller {
  transform: scale(.95);
  transition: transform .25s;
}

.goalSpacer {
  margin-bottom: 18px;
}

.footerSpacer {
  margin-bottom: 45px;
}

.footer {
  position: absolute;
  margin: 10px;
  bottom: 0;
  right: 34px;
}