.learnMore {
  position: relative;
  width: 1266px;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.title {
  position:relative;
  height: 52px;
  font-weight: bold;
  font-size: 20px;
  line-height: 52px;
  padding-left: 20px;
  color: #FFFFFF;
  background: #17A8D6;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
}

.title > img {
  position: absolute;
  top: 12px;
  right: 14px;
}

.title > img:hover {
  cursor: pointer;
}

.wrapper {
  display: inline-block;
  max-height: 500px;
  background-color: #FFFFFF;
  margin: 11px 10px 11px 0;
  overflow-y: scroll;
}

.list {
  list-style: none;
  margin: 0;
  padding-left: 20px;
  padding-right: 10px;
}

.list > li::before {
  content: "•";
  padding-right: 10px;
}

.itemTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #20324D
}

.itemMessage {
  margin-left: 15px;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.wrapper::-webkit-scrollbar {
  width: 8px;
}

.wrapper::-webkit-scrollbar-track {
  background-image: url("../icons/scroll_track.png");
  background-repeat: repeat-y;
  background-size: contain;
  /* border: solid 4px transparent; */
}

.wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #20324D;
  /* border: solid 4px transparent; */
}

.content {
  margin: 25px 36px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.goal {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 22px;
}

.bold {
  font-weight: 700;
}

.table {
  display: flex;
  flex-grow: 1;
}

.box {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  margin: 2px;
}

.tableTitle {
  padding-left: 10px;
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  color: white;
  background-color: #17A8D6;
}

.tableContent {
  border: 1px solid #C4C4C4;
  flex-grow: 1;
  font-size: 14px;
  line-height: 17px;
  padding: 14px;
}

.indentOne {
  margin-left: 0px;
}

.indentTwo {
  margin-left: 15px;
}

.indentThree {
  margin-left: 30px;
}