.button {
  background: #20324D;
  align-items: center;
  padding: 7px 20px;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  height: 34px;
  width: 117px;
  border-radius: 22px;
  border-width: 0px;
}

.disabled {
  background: #20324D80;
}

.button:hover {
  cursor: pointer;
}
