.goal {
  /* width: 321px; */
  width: 32%;
  height: 165px;
  margin: 4px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #FFFFFF;
  position: relative;
}

.goal:hover {
  cursor: pointer;
}

.selected {
  box-shadow: 0px 0px 0px 4px #27AE60;
}

.big {
  height: 185px;
  background-color: #20324D;
}

.level {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  padding: 5px 9px 4px 9px;
}

.level.green {
  background-color: #10AA51;
}

.level.blue {
  background-color: #2237EC;
}

.level.yellow {
  background-color: #F3B404;
}

.level.orange {
  background-color: #FD6C09;
}

.level.red {
  background-color: #EB5757;
}

.level.black {
  background-color: #474747;
}

.content {
  margin: 13px 14px 14px 14px;
}

.title {
  color: #373737;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-top: 13px;
  padding-bottom: 6px;
  border-bottom: 1px solid #373737;
  position: relative;
}

.goal.big > .content > .title {
  border-bottom: 1px solid #DBDBDB;
}

.title > img {
  position: relative;
  bottom: -2px;
  width: 21px;
  height: 21px;
}

.title > span {
  margin-left: 5px;
}

.body {
  color: #000000;
  font-size: 14px;
  line-height: 18px;
  margin-top: 6px;
}

.goal.big > .content > .title,
.goal.big > .content > .body {
  color: #FFFEFE;
}

.footer {
  width: 100%;
  background-color: #D2DCEB;
  color: #373737;
  position: absolute;
  bottom: 0px;
  padding: 4px 0px 4px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.goal.big > .footer {
  background-color: #FFFFFF;
  color: #20324D;
}

.footer > span {
  font-weight: 600;
}