.container {
  width: 100%;
  min-width: 1440px;
  height: 100vh;
  min-height: 900px;
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #F4F4F5;
  flex-grow: 1;
}

.bold {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}

.text {
  font-family: Source Sans Pro;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  margin-left: 37px;
}

/* .left, .right {
  min-height: 670px;
} */

.left{
  flex-grow: 1;
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px;
  margin-left: 37px;
}

.left > .reviewWrapper {
  flex-grow: 0;
  flex-basis: 45%;
  margin-bottom: 15px;
}

.left > .clinicWrapper {
  flex-grow: 0;
  flex-basis: 55%;
  margin-top: 15px;
}

.middle{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.right {
  flex-grow: 1;
  flex-basis: 55%;
  position: relative;
  margin: 15px;
  margin-right: 37px;
  display: flex;
  flex-direction: column;
}

.right > .previewWrapper {
  flex-grow: 1;
  margin-right: 15px;
  margin-left: 15px;
}

.footer {
  margin: 10px;
  margin-top: 25px;
}

.footer button {
  float: right;
}

.printTakehome {
  display: none;
}

@media print {
  @page {
    margin: 0;
  }
  
  body { 
    margin: 1.6cm;
  }

  .container {
    display: none;
  }

  .printTakehome {
    display: block;
  }
  
  .printScale {
    transform: scale(170%);
    transform-origin: top left;
  }
}
