.progress {
  height: 55px;
  background-color: #ffffff;
  display: flex;
  padding-top: 15px;
}

.flex {
  flex-grow: 1;
}

.flex > ul {
  counter-reset: step;
  margin-block: 0;
  padding-inline: 0;
  margin-inline: 0;
  display: flex;
  justify-content: center;
}

.flex > ul > li {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #979797;
  position: relative;
  list-style-type: none;
  width: 256px;
  float: left;
  text-align: center;
}

.flex > ul > li:before {
  width: 22px;
  height: 22px;
  content: counter(step);
  counter-increment: step;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  display: block;
  margin: 0 auto 7px auto;
  border: 1px solid #BCCCDD;
  border-radius: 50%;
  background-color: #DBDBDB;
  position: relative;
  z-index: 2;
}

.flex > ul > .check:before {
  content: "";
  background: no-repeat center/70% url('../icons/check_white.svg');
  background-color: #DBDBDB;
}

.flex > ul > li:after {
  width: 100%;
  height: 4px;
  content: '';
  position: absolute;
  background-color: #8CA3BB;
  top: 10px;
  left: -50%;
  z-index: 1;
}

.flex > ul > li:first-child:after {
  content: none;
}

.flex > ul > li.activeText {
  font-weight: 600;
  color: #20324D;
}

.flex > ul > li.active:before {
  border: 1px solid #20324D;
  background-color: #20324D;
  color: #ffffff
}

.flex > ul > li.active + li:after {
  background-color: #19273B;  
}

.name {
  flex-grow: 0;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  margin-top: 5px;
  margin-right: 43px;
  padding: 0 13px 0 13px;
  color: #0B447E;
  height: 26px;
  border-radius: 26px;
  background-color: #DDDDDD;
}

.clickable::before,
.clickable span {
  cursor: pointer;
}