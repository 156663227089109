.panel {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #20324D;
  display: flex;
  align-items: baseline;
  padding-right: 21px;
  padding-left: 26px;
}

.title {
  flex-grow: 1;
  font-weight: bold;
  font-size: 20px;
  line-height: 72px;
  color: #FFFFFF;
}

.toggleText {
  color: #ffffff;
  font-weight: 300;
  line-height: 72px;
  font-size: 15px;
  margin-right: 7px;
}

.wrapper {
  color: #ffffff;
  font-weight: 300;
  font-size: 15px;
  float: right;
  margin: 17px 26px 0 0;
  line-height: 80px;
}

.content {
  margin: 0 38px 38px 38px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.phoneIcon {
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.printIcon {
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;
  line-height: 72px;
  position: relative;
}

.printIcon > img {
  position: relative;
  top: 5px;
  height: 25px;
  width: 25px;
}

.previewtext {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #20324D;
}

.subtext {
  text-align: center;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  color: #20324D;
}

.takehomeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow-y: hidden;
}

.preview {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.enlargePreview {
  text-align: right;
  margin-right: 70px;
}

.enlargePreview > p {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  color: #8B8B8B;
}

.previewWrapper {
  position: relative;
  width: calc(610px * .85);
  height: 100%;
}

.previewScale {
  position: absolute;
  transform: scale(.75);
  transform-origin: top left;
  cursor: pointer;
}

.boxShadow {
  margin: 6px;
  box-shadow: 0px 5.57798px 5.57798px rgba(0, 0, 0, 0.25);
}

.materials {  
  text-align: center; 
  margin-bottom: 20px;
}

.materialsComment {
  display: inline-block;
  background: #20324D;
  border-radius: 22px;
  padding: 0 17px;
  font-size: 16px;
  line-height: 34px;
  color: #FFFFFF;
}

.dialogWrapper {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  margin: 10px;
}

.dialogWrapper::-webkit-scrollbar {
  width: 8px;
}

.dialogWrapper::-webkit-scrollbar-track {
  background-image: url("../icons/scroll_track.png");
  background-repeat: repeat-y;
  background-size: contain;
}

.dialogWrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #20324D;
}

.closeDialog {
  cursor: pointer;
  position: absolute;
  top: 0; 
  right: -50px;
  z-index: 2000;
}

.printScale {
  transform: scale(170%);
  transform-origin: top left;
}

.printFullsize {
  width: 1050px;
  height: 1250px;
}
