.title {
  background-color: #CA2D2D;
  height: 41px;
  line-height: 41px;
  padding-left: 14px;
  padding-right: 14px;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  line-height: 41px;
}

.green {
  background-color: #10AA51;
}

.message {
  color: #121212;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 13px 14px 15px 14px;
  max-width: 330px;
}