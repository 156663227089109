.blackout {
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.65);
}

.popper {
  z-index: 600;
}

.whereGoal {
  margin: 0 0 0px 100px;
  padding: 20px 36px 20px 76px;
  border-radius: 10px;
  max-width: 792px;
  position: relative;
  background-color: #ffffff;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #2E99B4;
  margin-bottom: 10px;
}

.message {
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #373737;
}

.message > span {
  cursor: pointer;
  font-weight: 600;
  color: #2E99B4;
}

.arrow {
  position: absolute;
  top: 65px;
  left: -85px
}

.lightbulb {
  position: absolute;
  top: 15px;
  left: 19px;
}

.dimiss {
  position: absolute;
  top: 12px;
  right: 18px;
  cursor: pointer;
}