.buttonToggle {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  color: #20324D;
  width: 142px;
  height: 30px;
  background-color: #FFFFFF;
  border: 1px solid #27AE60;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.part {
  display: inline-block;
  width: 50%;
  height: 100%;
  text-align: center;
}

.selected {
  background-color: #27AE60;
  color: #FFFFFF;
}