.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  font-size: 26px;
  font-weight: 700;
}

.part {
  max-width: 60%;
}