.title {
  position: relative;
  background-color: #30C759;
  height: 46px;
  line-height: 46px;
  padding-left: 14px;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
}

.title > img {
  position: absolute;
  top: 12px;
  right: 15px;
}

.content {
  position: relative;
  margin: 13px 14px 15px 14px;
}

.message {
  color: #121212;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

.separator {
  color: #DBDBDB;
  background-color: #DBDBDB;
  border: 0 none;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 14px;
  width: 100%;
}

.copy {
  cursor: pointer;
  float: right;
  font-size: 13px;
  line-height: 16px;
  color: #17A8D6;
}

.note {
  display: inline-block;
  width: calc(100% - 61px);
  resize: none;
  padding:15px;
  height: 160px;
  margin: 15px;
  font-size: 15px;
}

.note::-webkit-scrollbar {
  width: 8px;
}

.note::-webkit-scrollbar-track {
  background-image: url("../icons/scroll_track.png");
  background-repeat: repeat-y;
  background-size: contain;
  /* border: solid 4px transparent; */
}

.note::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #20324D;
  /* border: solid 4px transparent; */
}