.takeHome {
  font-family: 'Poppins', sans-serif;
  width: 612px;
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.header {
  display: flex;
  flex-direction: row;
  margin: 10px 33px 20px 33px;
}

.title {
  align-self: center;
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #20324D;
}

.titleSpa {
  font-size: 23px;
}

.for {
  align-self: center;
  font-size: 15px;
  line-height: 22px;
}

.goal {
  padding-left: 210px;
  position: relative;
  height: 280px;
}

.goalPerson {
  position: absolute;
  left: 10px;
}

.goalWrapper {
  position: relative;
  width: 358px;
}

.goalBox {
  color: #20324D;
  width: 100%;
  height: 100%;
  border: 1px solid #A0A0A0;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  z-index: 1400;
  padding: 10px 13px 10px 13px;
}

.goalBorder {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 100%;
  height: 100%;
  background: #30C759;
  border: 1px solid #19913A;
  border-radius: 10px;
  z-index: 1300;
}

.goalTitle {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #20324D;
}

.goalText {
  margin-top: 5px;
  font-size: 13px;
  line-height: 19px;
}

.goalDescription {
  margin-top: 10px;
  font-size: 10px;
  line-height: 15px;
}

.goalWhat {
  margin: 20px 20px 10px 30px;
}

.goalWhatTitle {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #20324D;
}

.goalWhatText {
  margin-left: 5px;
  font-size: 12px;
  line-height: 18px;
}

.help {
  display: flex;
  flex-direction: row;
  background-color: #20324D;
  width: 612px;
  min-height: 134px;
  color: #ffffff;
  position: relative;
}

.helpTitle {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 5px;
}

.helpBody {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

.helpLeft {
  margin: 14px 45px 14px 45px;
  flex-grow: 1;
  flex-basis: 0;
}

.helpRight {
  margin: 14px 45px 14px 45px;
  flex-grow: 1;
  flex-basis: 0;
}

.helpSeparator {
  border-right: solid 1px #939393;
  width: 0;
  margin: 14px 0 14px 0;
}

.confidence {
  margin: 20px 30px 10px 30px;
}

.confidenceTitle {
  margin-bottom: 5px;;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #20324D;
}

.confidenceLevel {
  font-size: 13px;
  line-height: 20px;
}

.bold {
  font-weight: 700;
}

.achieve {
  position: relative;
  padding-top: 10px;
  margin-top: 10px;
}

.achieveTitle {
  position: absolute;
  top: 0;
  left: 20px;
  background: #ffffff;
  font-size: 13px;
  line-height: 19px;
  color: #000000;
}

.achieveBox {
  height: 80px;
  border: 1px dashed #ACADAE;
  box-sizing: border-box;
  border-radius: 10px;
}

.footer {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
  margin: 10px 30px 10px 30px;
}

.footerUrl {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.footerDate {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}