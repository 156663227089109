.summary {
  background-color: #FFFFFF;
  width: 280px;
  padding: 20px 10px 12px 10px;
  color: #20324D;
  border: 1px solid #C5C5C5;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  position: relative;
}

.title {
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
  border-bottom: 5px solid #010101;
}

.entry > hr {
  color: #010101;
  background-color: #010101;
  border: 0 none;
  height: 1px;
}

.entry > hr.large {
  height: 3px;
}

.comment {
  font-size: 15px;
  line-height: 19px;
}

.bold {
  font-weight: 700;
}

.heavy {
  font-weight: 600;
}

.label {
  margin-top: 47px;
  margin-bottom: 16px;
  font-style: italic;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
}

.entry > div {
  display: flex;
  align-items:flex-end;
}

.separator {
  flex-grow: 1;
  border-bottom: 0.5px dashed #979797;
  margin: auto 10px 7px 10px;
}

.category {
  font-size: 22px;
  line-height: 28px;
}

.sub_category {
  font-size: 15px;
  line-height: 19px;
  margin-left: 11px;
}

.footerSpacer {
  margin-bottom: 95px;
}

.footer {
  position: absolute;
  bottom: 10px;
  margin-top: 50px;
  margin-right: 10px;
}

.note {
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
}

.green {
  color: #10AA51;
}

.red {
  color: #EB5757;
}

.blue {
  /* color: #2237EC; */
  color: #2287ec;
}